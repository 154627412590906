import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { convertNodeToElement } from 'react-html-parser';

// =============================
// Standard Filter Components
// =============================

export const SearchFilter = ({
  tableRef,
  col,
  label,
  filterKey,
  updateFilter,
  value,
}) => {
  const colIndex = col - 1;
  const [placeholder, setPlaceholder] = useState(label ? `${label}` : 'Search');
  const [searchValue, setSearchValue] = useState(value || '');

  useEffect(() => {
    if (!label && tableRef.current) {
      const th = tableRef.current.querySelector('thead tr')?.children[colIndex];
      if (th) {
        const text = th.textContent;
        setPlaceholder(text ? `Search ${text.trim()}` : 'Search');
      }
    }
  }, [tableRef, colIndex, label]);

  // Sync internal state if the value prop (e.g., from session storage) changes
  useEffect(() => {
    setSearchValue(value || '');
  }, [value]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setSearchValue(newValue);
    updateFilter(filterKey, newValue);
  };

  return (
    <input
      type="search"
      className={`input search-filter filter-col-${col}`}
      placeholder={placeholder}
      value={searchValue}
      onChange={handleChange}
    />
  );
};

SearchFilter.propTypes = {
  tableRef: PropTypes.object.isRequired,
  col: PropTypes.number.isRequired,
  label: PropTypes.string,
  filterKey: PropTypes.string.isRequired,
  updateFilter: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export const DropdownFilter = ({
  tableRef,
  col,
  label,
  filterKey,
  updateFilter,
  value,
}) => {
  const colIndex = col - 1;
  const [options, setOptions] = useState([]);
  const [headerText, setHeaderText] = useState(label || '');

  useEffect(() => {
    if (!label && tableRef.current) {
      const th = tableRef.current.querySelector('thead tr')?.children[colIndex];
      if (th) {
        setHeaderText(th.textContent.trim());
      }
    }
  }, [tableRef, colIndex, label]);

  useEffect(() => {
    if (tableRef.current) {
      let opts = new Set();
      tableRef.current.querySelectorAll('tbody tr').forEach((row) => {
        const cell = row.children[colIndex];
        if (cell) {
          cell.textContent.split(',').forEach((part) => {
            const trimmed = part.trim();
            if (trimmed) opts.add(trimmed);
          });
        }
      });
      setOptions(Array.from(opts).sort());
    }
  }, [tableRef, colIndex]);

  const handleChange = (e) => {
    updateFilter(filterKey, e.target.value);
  };

  return (
    <select
      className={`select dropdown-filter filter-col-${col}`}
      value={value || ''}
      onChange={handleChange}
    >
      <option value="">{headerText ? `${headerText}` : 'All'}</option>
      {options.map((opt, i) => (
        <option key={i} value={opt}>
          {opt}
        </option>
      ))}
    </select>
  );
};

DropdownFilter.propTypes = {
  tableRef: PropTypes.object.isRequired,
  col: PropTypes.number.isRequired,
  label: PropTypes.string,
  filterKey: PropTypes.string.isRequired,
  updateFilter: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export const CheckmarkFilter = ({
  tableRef,
  col,
  label,
  filterKey,
  updateFilter,
  checked,
}) => {
  const colIndex = col - 1;
  const [headerText, setHeaderText] = useState(label || '');

  useEffect(() => {
    if (!label && tableRef.current) {
      const th = tableRef.current.querySelector('thead tr')?.children[colIndex];
      if (th) {
        setHeaderText(th.textContent.trim());
      }
    }
  }, [tableRef, colIndex, label]);

  const handleChange = (e) => {
    updateFilter(filterKey, e.target.checked);
  };

  return (
    <label className={`checkmark checkmark-filter filter-col-${col}`}>
      <input type="checkbox" checked={checked} onChange={handleChange} />
      {headerText ? `${headerText}` : 'Checkmark'}
    </label>
  );
};

CheckmarkFilter.propTypes = {
  tableRef: PropTypes.object.isRequired,
  col: PropTypes.number.isRequired,
  label: PropTypes.string,
  filterKey: PropTypes.string.isRequired,
  updateFilter: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};

export const CombinedCheckmarkSelect = ({
  tableRef,
  cols,
  label,
  optionLabels,
  filterKey,
  updateFilter,
  value,
}) => {
  const handleChange = (e) => {
    updateFilter(filterKey, e.target.value);
  };

  return (
    <select
      className="select dropdown-filter checkmark-combined-filter"
      value={value || ''}
      onChange={handleChange}
    >
      <option value="">{label ? `${label}` : 'All'}</option>
      {optionLabels.map((opt, i) => (
        <option key={i} value={opt.toLowerCase()}>
          {opt}
        </option>
      ))}
    </select>
  );
};

CombinedCheckmarkSelect.propTypes = {
  tableRef: PropTypes.object.isRequired,
  cols: PropTypes.arrayOf(PropTypes.number).isRequired,
  label: PropTypes.string,
  optionLabels: PropTypes.arrayOf(PropTypes.string),
  filterKey: PropTypes.string.isRequired,
  updateFilter: PropTypes.func.isRequired,
  value: PropTypes.string,
};

CombinedCheckmarkSelect.defaultProps = {
  label: '',
  optionLabels: ['Manual', 'Direct', 'Both'],
  value: '',
};

// =============================
// TableWithFilters Component
// =============================

const TableWithFilters = ({ node, transform, index }) => {
  const customFilters = node.attribs['data-filters']
    ? JSON.parse(node.attribs['data-filters'])
    : null;
  const legacyFilter = node.attribs['data-filter'] || '';

  // Find the table node
  const tableNode = node.children.find(
    (child) => child.type === 'tag' && child.name === 'table'
  );

  // Create a ref for the table element
  const tableRef = useRef(null);

  // State to hold active filters (changes trigger the filtering effect only)
  const [activeFilters, setActiveFilters] = useState({});

  // Unique storage key for this table's filters
  const storageKey = `tableFilters-${index}`;

  // On mount, load filters from session storage if available
  useEffect(() => {
    const savedFilters = sessionStorage.getItem(storageKey);
    if (savedFilters) {
      setActiveFilters(JSON.parse(savedFilters));
    }
  }, [storageKey]);

  // Whenever filters change, update session storage
  useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(activeFilters));
  }, [storageKey, activeFilters]);

  const updateFilter = (filterKey, value) => {
    setActiveFilters((prev) => {
      const newFilters = { ...prev };
      if (typeof value === 'string') {
        if (value.trim() === '') {
          delete newFilters[filterKey];
        } else {
          newFilters[filterKey] = value;
        }
      } else {
        newFilters[filterKey] = value;
      }
      return newFilters;
    });
  };

  // Filtering effect: update row styles without re-rendering table content
  useEffect(() => {
    const timer = setTimeout(() => {
      if (tableRef.current) {
        tableRef.current.querySelectorAll('tbody tr').forEach((row) => {
          let visible = true;
          for (const [key, filterValue] of Object.entries(activeFilters)) {
            const [filterType, keyPart] = key.split('-');
            if (filterType === 'checkmarkCombined') {
              const cols = keyPart.split(',').map((n) => parseInt(n, 10) - 1);
              if (filterValue) {
                if (filterValue === 'manual') {
                  const cell = row.children[cols[0]];
                  if (!cell || !hasCheckIndicator(cell)) {
                    visible = false;
                    break;
                  }
                } else if (filterValue === 'direct') {
                  const cell = row.children[cols[1]];
                  if (!cell || !hasCheckIndicator(cell)) {
                    visible = false;
                    break;
                  }
                } else if (filterValue === 'both') {
                  const cell1 = row.children[cols[0]];
                  const cell2 = row.children[cols[1]];
                  if (
                    !cell1 ||
                    !cell2 ||
                    !hasCheckIndicator(cell1) ||
                    !hasCheckIndicator(cell2)
                  ) {
                    visible = false;
                    break;
                  }
                }
              }
            } else {
              const colIndex = parseInt(key.split('-')[1], 10) - 1;
              const cell = row.children[colIndex];
              if (!cell) {
                visible = false;
                break;
              }
              const cellText = cell.textContent.toLowerCase();
              if (filterType === 'search' || filterType === 'dropdown') {
                if (
                  filterValue &&
                  !cellText.includes(filterValue.toLowerCase())
                ) {
                  visible = false;
                  break;
                }
              } else if (filterType === 'checkmark') {
                if (filterValue === true && !hasCheckIndicator(cell)) {
                  visible = false;
                  break;
                }
              }
            }
          }
          row.style.display = visible ? '' : 'none';
        });
      }
    }, 150);
    return () => clearTimeout(timer);
  }, [activeFilters]);

  // Helper function to check if a cell contains a check indicator.
  const hasCheckIndicator = (cell) => {
    if (!cell) return false;
    const text = cell.textContent.toLowerCase();
    const img = cell.querySelector('img');
    return (
      text.includes('check') ||
      text.includes('✅') ||
      (img && img.getAttribute('src') === '/images/check-solid-01.png')
    );
  };

  // Freeze table content so it is only generated once (avoiding re-render on filter change)
  const tableContent = useMemo(() => {
    return tableNode.children.map((n, i) => {
      const elem = convertNodeToElement(n, i, transform);
      return typeof elem === 'string' && elem.trim() === '' ? null : elem;
    });
  }, [tableNode, transform]);

  // Generate filter components (as before)
  const filtersComponents = useMemo(() => {
    if (customFilters) {
      return customFilters.map((filterObj) => {
        const { type, col, cols, label, optionLabels } = filterObj;
        if (type === 'search') {
          const filterKey = `search-${col}`;
          return (
            <SearchFilter
              key={filterKey}
              tableRef={tableRef}
              col={col}
              label={label}
              filterKey={filterKey}
              updateFilter={updateFilter}
              value={activeFilters[filterKey] || ''}
            />
          );
        } else if (type === 'dropdown') {
          const filterKey = `dropdown-${col}`;
          return (
            <DropdownFilter
              key={filterKey}
              tableRef={tableRef}
              col={col}
              label={label}
              filterKey={filterKey}
              updateFilter={updateFilter}
              value={activeFilters[filterKey] || ''}
            />
          );
        } else if (type === 'checkmarkCombined') {
          const filterKey = `checkmarkCombined-${cols.join(',')}`;
          return (
            <CombinedCheckmarkSelect
              key={filterKey}
              tableRef={tableRef}
              cols={cols}
              label={label}
              optionLabels={optionLabels}
              filterKey={filterKey}
              updateFilter={updateFilter}
              value={activeFilters[filterKey] || ''}
            />
          );
        } else if (type === 'checkmark') {
          const filterKey = `checkmark-${col}`;
          return (
            <CheckmarkFilter
              key={filterKey}
              tableRef={tableRef}
              col={col}
              label={label}
              filterKey={filterKey}
              updateFilter={updateFilter}
              checked={activeFilters[filterKey] || false}
            />
          );
        }
        return null;
      });
    } else {
      return legacyFilter
        .split(' ')
        .filter(Boolean)
        .map((filterDef) => {
          const [filterType, colStr] = filterDef.split('-');
          const colIndex = parseInt(colStr, 10);
          const filterKey = `${filterType}-${colStr}`;
          const currentValue =
            activeFilters[filterKey] !== undefined
              ? activeFilters[filterKey]
              : filterType === 'checkmark'
              ? false
              : '';
          if (filterType === 'search') {
            return (
              <SearchFilter
                key={filterKey}
                tableRef={tableRef}
                col={colIndex}
                label={null}
                filterKey={filterKey}
                updateFilter={updateFilter}
                value={currentValue}
              />
            );
          } else if (filterType === 'dropdown') {
            return (
              <DropdownFilter
                key={filterKey}
                tableRef={tableRef}
                col={colIndex}
                label={null}
                filterKey={filterKey}
                updateFilter={updateFilter}
                value={currentValue}
              />
            );
          } else if (filterType === 'checkmark') {
            return (
              <CheckmarkFilter
                key={filterKey}
                tableRef={tableRef}
                col={colIndex}
                label={null}
                filterKey={filterKey}
                updateFilter={updateFilter}
                checked={currentValue}
              />
            );
          }
          return null;
        });
    }
  }, [customFilters, legacyFilter, tableRef, activeFilters]);

  return (
    <>
      {(customFilters || legacyFilter) && (
        <div className="table-filters" style={{ marginBottom: '1rem' }}>
          {filtersComponents}
        </div>
      )}
      <div key={index} className="tbl table-scroll-wrapper">
        <table ref={tableRef} className="table" {...tableNode.attribs}>
          {tableContent}
        </table>
      </div>
    </>
  );
};

TableWithFilters.propTypes = {
  node: PropTypes.object.isRequired,
  transform: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default TableWithFilters;
